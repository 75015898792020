import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { resetAdmin } from '../../Reducers/adminReducer';
import { resetCardData } from '../../Reducers/cardReducer';
import { resetCustomers } from '../../Reducers/customersReducer';
import { resetDashboardStats } from '../../Reducers/dashboardReducer';
import { resetInvoices } from '../../Reducers/invoicesReducer';
import { resetReducer } from '../../Reducers/signupReducer';
import { resetSubscriptionData } from '../../Reducers/subscriptionReducer';
import { resetUsers } from '../../Reducers/usersReducer';
import { AxiosErrorResponse } from '../../Types';
// This file will be used for adding helper functions

export const formatDate = (dateString: string) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options as Intl.DateTimeFormatOptions).replace(/,/g, '');
};

export const formatBillingMonth = (month: string) => {
  const monthString = month.toString();
  const year = parseInt(monthString.slice(0, 4), 10);
  const monthNumber = parseInt(monthString.slice(-2), 10);
  const monthName = new Date(year, monthNumber - 1).toLocaleString('default', { month: 'short' });
  return `${monthName} ${year}`;
};

export {};
export const makeFirstLetterCapital = (value: string) => {
  return value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
};
type NavigateFunction = ReturnType<typeof useNavigate>;
export const handleLogoutAndReset = (dispatch: Dispatch, navigate: NavigateFunction) => {
  dispatch(resetReducer());
  dispatch(resetCardData());
  dispatch(resetDashboardStats());
  dispatch(resetSubscriptionData());
  dispatch(resetInvoices());
  dispatch(resetUsers());
  dispatch(resetCustomers());
  dispatch(resetAdmin());
  navigate('/');
};

export const validateEmail = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._-]*[a-zA-Z0-9-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

export const constructApiUrl = (
  basePath: string,
  isAdmin: boolean,
  customerId: number | undefined,
  isExtraParam?: boolean,
) => {
  let url = basePath;
  let partialUrl = '?customer=';
  if (isExtraParam) {
    partialUrl = '&customer=';
  }
  if (isAdmin && customerId) {
    url += `${partialUrl}${customerId}`;
  }
  return url;
};

export const formatExpiryDate = (data: string) => {
  const date = new Date(data);
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  const year = date.getFullYear() % 100;
  const formattedDate = `${month.toString().padStart(2, '0')}/${year.toString().padStart(2, '0')}`;
  return formattedDate;
};

export const isWithinDays = (startDate: Date | string, duration: number): boolean => {
  if (startDate === '') return true;
  const currentDate = new Date();
  const dateDifference = Math.abs(currentDate.getTime() - new Date(startDate).getTime());
  const differenceInDays = Math.ceil(dateDifference / (1000 * 60 * 60 * 24));

  return differenceInDays <= duration;
};

export const splitAccountName = (accName: string, isBusinessUser: boolean) => {
  const targetHyphenCount = isBusinessUser ? 3 : 4;

  let hyphenCount = 0;
  let targetIndex = -1;

  // Loop through the account name to find the index of the desired hyphen
  for (let i = 0; i < accName.length; i++) {
    if (accName[i] === '-') {
      hyphenCount++;
      if (hyphenCount === targetHyphenCount) {
        targetIndex = i;
        break;
      }
    }
  }

  // Split the account name at the target index if it's found
  if (targetIndex !== -1) {
    const substringBefore = accName.substring(0, targetIndex + 1); // Include the hyphen in the substring
    const substringAfter = accName.substring(targetIndex + 1);
    return {
      substringBefore,
      substringAfter,
    };
  }
};

export const isExpired = (expiryDate: string) => {
  const [month, year] = expiryDate.split('/');
  const expiry = new Date(parseInt('20' + year), parseInt(month) - 1);

  const now = new Date();

  now.setDate(1);
  expiry.setDate(1);

  return now > expiry;
};

export const formatDateString = (date: string | null, format = 'MMM DD, YYYY') => {
  if (!date || !moment(date).isValid()) {
    return '';
  }

  return moment(date).format(format);
};

export const isUnauthorizedError = (error: AxiosErrorResponse): boolean => {
  return (
    error.response?.status === 401 ||
    error.response?.code === 401 ||
    error.message === 'Network Error'
  );
};

export const toCamelCase = (str: string): string => {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
};

export const identificationType = (id: string) => {
  if (id) return id[0] === '2' ? 'Iqama ID' : 'National ID';
  return null;
};

export const getTotalSecondsFromCreatedAt = (createdAt: Date) => {
  const createdTime = new Date(createdAt).getTime();
  const currentTime = Date.now();
  const timeDiff = currentTime - createdTime;
  const totalSeconds = Math.floor(timeDiff / 1000);
  return totalSeconds;
};
